import { Record } from 'immutable';
import { Reducer } from 'redux';
import { from, fromEvent, iif, merge, of } from 'rxjs';
import { combineEpics, Epic, ofType } from 'redux-observable';
import {
  catchError,
  debounceTime,
  ignoreElements,
  map,
  mergeMap,
  pluck,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { getUser } from 'core/User';
import refillRepository from './repositories/refill.repository';
import { countriesDictionary, refillAdapter } from './refill.util';
import { refillActionsTypes, refillMethodActions } from './interfaces/actions.refill.interfaces';
import {
  ILastPayment,
  IPaymentMethodV2,
  IPaymentsDto,
  IPaymentsDtoV2,
  IPayMethod,
  IPayMethodGate,
  IRefill,
  PaymentTypes,
} from './interfaces/reduces.refill.interfaces';
import { getRefillCountry } from './selectors';
import { getAppLocale } from '../../core/AppShell/selectors';
import { currencyService } from 'services/currency';
import { FreeCaseReward, IReferralInfo } from 'core/User/interfaces';
import { actionReferrerUserInfoResponse } from 'core/User/ducks/duck';
import { URLS } from 'services/constant-urls';
import { IDispatch, IEventsTypes } from 'core/rootInterfaces/root.interface';
import { dialogChangeState, dialogOn } from 'core/ducks/dialog';
import { userModals } from 'core/User/modal.config';
import { isNaN } from 'lodash';
import { RefillIcons } from './assets';

interface IPaymentGroup {
  method: IPaymentMethodV2;
  gates: IPayMethodGate[];
}

const RefillMethodRecord = Record({
  refillCountry: '',
  autoRefillCountry: '',
  loading: !localStorage.getItem('@csgofast:refill-country'),
  config: {
    systems: [],
    regional: [],
    popular: [],
  },
  lastPayment: null,
  trust: false,
  events: {
    tickets: null,
    bonus: null,
  },
});

const refill: Reducer<Record<IRefill>, refillMethodActions> = (
  state = new RefillMethodRecord(),
  action
) => {
  switch (action.type) {
    case refillActionsTypes.SET_SELECT_COUNTRY: {
      const { refillCountry } = action.payload;
      return state.set('refillCountry', refillCountry);
    }

    case refillActionsTypes.AUTO_SELECT_COUNTRY_RESPONSE: {
      const { autoRefillCountry } = action.payload;
      return state.set('autoRefillCountry', autoRefillCountry);
    }

    case refillActionsTypes.REFILL_LOADING: {
      const { loading } = action.payload;
      return state.set('loading', loading);
    }

    case refillActionsTypes.FETCH_REFILL_METHODS_SUCCESS: {
      const { success, lastSuccess, depositBonus, ...configs } = action.payload.methods;
      return state.setIn(['config'], configs).setIn(['events', 'bonus'], depositBonus);
    }

    case refillActionsTypes.UPDATE_LAST_PAYMENT: {
      return action.payload.lastPayment.method === 'unknown'
        ? state
        : state.set('lastPayment', action.payload.lastPayment);
    }

    case refillActionsTypes.UPDATE_TRUST_USER: {
      const { trust } = action.payload;
      return state.setIn(['trust'], trust);
    }

    case refillActionsTypes.UPDATE_AVAILABLE_TICKETS: {
      const { amount } = action.payload;
      return state.setIn(['events', 'tickets'], amount);
    }

    default: {
      return state;
    }
  }
};

export default refill;

export const attachListener = () => ({
  type: refillActionsTypes.REFILL_ATTACH_LISTENERS,
});

export const detachListener = () => ({
  type: refillActionsTypes.REFILL_DETACH_LISTENERS,
});

export const updateSelectCountry = (refillCountry?: string) => ({
  type: refillActionsTypes.SET_SELECT_COUNTRY,
  payload: {
    refillCountry,
  },
});

export const autoSelectCountry = () => ({
  type: refillActionsTypes.AUTO_SELECT_COUNTRY,
});

export const autoSelectCountryResponse = (autoRefillCountry?: string) => ({
  type: refillActionsTypes.AUTO_SELECT_COUNTRY_RESPONSE,
  payload: {
    autoRefillCountry,
  },
});

export const refillLoading = (loading: boolean) => ({
  type: refillActionsTypes.REFILL_LOADING,
  payload: {
    loading,
  },
});

const receiveAvailableMethod = (methods: any) => ({
  type: refillActionsTypes.FETCH_REFILL_METHODS_SUCCESS,
  payload: {
    methods,
  },
});

const updateLastPayment = ({ lastPayment }: ILastPayment | any) => ({
  type: refillActionsTypes.UPDATE_LAST_PAYMENT,
  payload: {
    lastPayment,
  },
});

// const updateTrustUser = (trust: boolean) => ({
//   type: refillActionsTypes.UPDATE_TRUST_USER,
//   payload: {
//     trust,
//   },
// })

export type ICreatePayout = {
  name?: string;
  value?: number;
  gate?: IPayMethodGate;
  wallet?: string;
  isOpenNewTab?: boolean;
};

export const createPayout = (config: ICreatePayout) => ({
  type: refillActionsTypes.CREATE_PAYOUT,
  payload: {
    config,
  },
});

export const activatedDepositCode = (code: string) => ({
  type: refillActionsTypes.ACTIVATED_DEPOSIT_CODE,
  payload: {
    code,
  },
});

// const updateEventTickets = (amount: number) => ({
//   type: refillActionsTypes.UPDATE_AVAILABLE_TICKETS,
//   payload: {
//     amount,
//   },
// })
export const actionReferralBonus = () => ({
  type: refillActionsTypes.ACTION_REFERRAL_BONUS_REFILL,
});

const refillReferralBonusEpic: Epic = action$ =>
  action$.pipe(
    ofType(refillActionsTypes.ACTION_REFERRAL_BONUS_REFILL),
    switchMap(() =>
      refillRepository.fetchReferralBonus().pipe(
        map(({ response }: { response: IReferralInfo }) =>
          actionReferrerUserInfoResponse(response)
        ),
        catchError(() => of())
      )
    )
  );

const refillInfoEpic: Epic = (action$, _store$, { socket }) =>
  action$.pipe(
    ofType(refillActionsTypes.REFILL_ATTACH_LISTENERS),
    debounceTime(700),
    switchMap(() =>
      merge(
        of(localStorage.getItem('@csgofast:refill-country')).pipe(
          switchMap(refillCountry => {
            const isNewKey = isNaN(+refillCountry);
            return iif(
              () => !!refillCountry && isNewKey && !!countriesDictionary[refillCountry],
              of(updateSelectCountry(refillCountry)),
              from(refillRepository.fetchUserCountry()).pipe(
                switchMap(({ response }) =>
                  of(
                    autoSelectCountryResponse(response.country ?? ''),
                    dialogOn(userModals.REFILL_COUNTRY)
                  )
                ),
                catchError(() =>
                  of(autoSelectCountryResponse(localStorage.getItem('@csgofast:refill-country')))
                )
              )
            );
          })
        ),
        fromEvent<ILastPayment | any>(socket.io, 'payout:api:response').pipe(
          map(({ lastPayment }) => updateLastPayment(lastPayment))
        )
        // refillRepository.fetchUserCountry().pipe(
        //   map(({ response }) => {
        //     const idx = alfa2code.findIndex(i => i === response.country.toUpperCase())
        //     return Number(localStorage.getItem('@csgofast:refill-country')) || idx
        //   }),
        //   map(countryIdx => updateSelectCountry(countryIdx)),
        //   catchError(() => of(updateSelectCountry(Number(localStorage.getItem('@csgofast:refill-country')))))
        // )
        // refillRepository
        //   .fetchCheckTrustUser(getUser(store$.value).id)
        //   .pipe(map(({ response }) => updateTrustUser(response.status)))
        // refillRepository.fetchEventTickets().pipe(
        //   map(({ response }) => updateEventTickets(response.amount)),
        //   catchError(() => of(updateEventTickets(null)))
        // )
      ).pipe(takeUntil(action$.ofType(refillActionsTypes.REFILL_DETACH_LISTENERS)))
    )
  );

const methodsEpic: Epic = (action$, store$) =>
  action$.pipe(
    ofType(refillActionsTypes.SET_SELECT_COUNTRY),
    pluck('payload', 'refillCountry'),
    tap(refillCountry => localStorage.setItem('@csgofast:refill-country', refillCountry)),
    map(refillCountry => ({
      userId: getUser(store$.value).id,
      refillCountry,
    })),
    mergeMap(({ userId, refillCountry }) =>
      refillRepository.fetchRefillMethod(userId, refillCountry).pipe(
        map(({ response }) => transformV2ToV1(response)),
        map(({ popular, regional, systems, depositBonus, payFee }) => {
          const steamType = regional
            .filter((item: IPayMethod) =>
              item.gates.some((gate: IPayMethodGate) => gate.walletType === 'steam')
            )
            .map((item: IPayMethod) => ({
              ...item,
              icon: RefillIcons.SihLogo,
              title: 'Via SIH account',
            }));

          const filteredRegional = regional.filter(
            (item: IPayMethod) =>
              !item.gates.some((gate: IPayMethodGate) => gate.walletType === 'steam')
          );

          return {
            popular: refillAdapter([...steamType, ...popular], payFee),
            regional: refillAdapter(filteredRegional, payFee),
            systems: refillAdapter(systems, payFee),
            depositBonus,
          };
        }),
        map(response => receiveAvailableMethod(response)),
        catchError(() => of())
      )
    )
  );

const payoutEpic: Epic = (action$, store$) =>
  action$.pipe(
    ofType(refillActionsTypes.CREATE_PAYOUT),
    pluck('payload', 'config'),
    tap(({ name, value, gate, wallet, isOpenNewTab }: ICreatePayout) => {
      const userId = getUser(store$.value).id;
      const refillCountry = getRefillCountry(store$.value);
      const lang = getAppLocale(store$.value);

      let currency = currencyService.getCurrency().key.toUpperCase();
      if (!gate.currency.includes(currency)) {
        currency = gate.currency[0].toUpperCase();
      }

      let amount: string;
      if (value) {
        amount = (value / currencyService.getRate('fastcoins_refill') / 100).toFixed(2);
        switch (currency) {
          case 'EUR': {
            amount = currencyService.convertBySelectedRateKey(amount, 'eur');
            break;
          }
          default: {
            amount = currencyService.convertBySelectedRateKey(amount, 'usd');
          }
        }
      }

      const params = new URLSearchParams();
      params.append('hostname', window.location.hostname);
      params.append('country', refillCountry);
      currency && params.append('currency', currency);
      lang && params.append('lang', lang);
      gate && params.append('gate', gate.gate_id.toString());
      wallet && params.append('wallet', wallet);
      const isNewTab = isOpenNewTab ? '_blank' : '_self';

      window.open(`${URLS.paymentURL}/${name}/${userId}/${amount || 5}/?${params}`, isNewTab);
    }),
    ignoreElements()
  );

// const depositCodesEpic: Epic = action$ =>
//   action$.pipe(
//     ofType(refillActionsTypes.ACTIVATED_DEPOSIT_CODE),
//     debounceTime(700),
//     pluck('payload', 'code'),
//     tap(code => {
//       socketService.io.emit('deposit.enter', { code })
//     }),
//     ignoreElements()
//   )

const freeCaseReward = (data: FreeCaseReward) => (dispatch: IDispatch) => {
  dispatch(dialogChangeState(userModals.FREE_CASE_BONUS, data));
};
export const refillEpics = combineEpics(
  refillInfoEpic,
  methodsEpic,
  payoutEpic,
  refillReferralBonusEpic
  // depositCodesEpic
);
export const eventsTypes: IEventsTypes[] = [
  { event: 'referral-bonus:add-cases', action: freeCaseReward },
];

export function transformV2ToV1(input: IPaymentsDtoV2): IPaymentsDto {
  const filteredMethods = filterMethods(input.methods);
  const groupedMethods = groupPaymentMethods(filteredMethods);
  return buildPaymentResult(input, groupedMethods);
}

function filterMethods(methods: IPaymentMethodV2[]): IPaymentMethodV2[] {
  return methods.filter(
    (item: IPaymentMethodV2) =>
      item.method_name !== 'giftcards_copy' && item.gate_title !== 'swapped'
  );
}

function groupPaymentMethods(methods: IPaymentMethodV2[]): Map<string, IPaymentGroup> {
  const groups = new Map<string, IPaymentGroup>();

  for (const method of methods) {
    const { category, isPopular } = determineMethodCategory(method);

    if (isPopular) {
      addMethodToGroup(groups, method, PaymentTypes.POPULAR);
    }

    addMethodToGroup(groups, method, category);
  }

  return groups;
}

function determineMethodCategory(
  method: IPaymentMethodV2
): {
  category: string;
  isPopular: boolean;
} {
  const isSystem = method.method_name === 'card' && method.group_name.includes(PaymentTypes.CARDS);
  const isPopular = method.group_name.includes(PaymentTypes.POPULAR);

  return {
    category: isSystem ? 'systems' : 'regional',
    isPopular,
  };
}

function addMethodToGroup(
  groups: Map<string, IPaymentGroup>,
  method: IPaymentMethodV2,
  category: string
): void {
  const groupKey = `${category}_${method.method_name}`;

  if (!groups.has(groupKey)) {
    groups.set(groupKey, { method, gates: [] });
  }

  const gate = createMethodGate(method);
  groups.get(groupKey)!.gates.push(gate);
}

function createMethodGate(method: IPaymentMethodV2): IPayMethodGate {
  return {
    id: method.id,
    gate_id: method.gate_id,
    name: method.gate_title.toLowerCase(),
    icon: typeof method.icon === 'string' ? method.icon : false,
    is_enabled: true,
    title: method.gate_title,
    method: method.method_name,
    comission: formatCommission(method.fees),
    walletType: method.walletType,
    currency: method.currency,
    method_name: method.method_title,
    minPayment: method.minPayment,
    maxPayment: method.maxPayment,
  };
}

function buildPaymentResult(
  input: IPaymentsDtoV2,
  groups: Map<string, IPaymentGroup>
): IPaymentsDto {
  const result: IPaymentsDto = {
    success: true,
    lastSuccess: null,
    depositBonus: input.depositBonus,
    payFee: input.payFee,
    systems: [],
    regional: [],
    popular: [],
    time: input.time,
  };

  groups.forEach((group, key) => {
    const payMethod = createPayMethod(group);
    const [category] = key.split('_');

    if (category === 'systems') result.systems.push(payMethod);
    if (category === 'regional') result.regional.push(payMethod);
    if (category === 'popular') result.popular.push(payMethod);
  });

  return result;
}

function createPayMethod(group: IPaymentGroup): IPayMethod {
  return {
    name: group.method.method_name,
    title: group.method.method_title,
    gates: group.gates,
    is_disabled: false,
    icon: `https://upi.csgofastbackend.com/icon/${group.gates[0].id}`,
  };
}
function formatCommission(fees: { percent: number; fixed: number }): string {
  const parts = [];
  if (fees.percent > 0) parts.push(`${fees.percent}%`);
  if (fees.fixed > 0) parts.push(`${fees.fixed} fixed`);
  return parts.join(' + ') || '';
}
