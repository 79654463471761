import { generateURL } from 'core/utils';
import { ajax } from 'rxjs/ajax';
import { socketService } from 'services/socket.service';
import { RequestBody } from '../interfaces/case-battle.interfaces';

class CasesBattleRepository {
  getCaseBattleList(sortBy: string) {
    return ajax({
      url: generateURL(
        { sortBy, allGames: true },
        `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/battles`
      ),
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  getMyCaseBattleList() {
    return ajax({
      url: generateURL(
        { me: true },
        `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/battles`
      ),
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }
  getCaseBattleById(id: number) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/battles/${id}`,
      method: 'GET',
      withCredentials: true,
    });
  }
  getCaseBattleHistory({
    force = false,
    limit = 10,
    offset = 0,
    sortBy = '-finished_at',
    user = false,
  }: Partial<{
    force: boolean;
    limit: number;
    offset: number;
    sortBy: string;
    user: boolean;
  }> = {}) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${
        socketService.domain
      }api/cases/battles/history?sortBy=${sortBy}${!!force ? `&force=${force}` : ''}${
        !!user ? `&user=${user}` : ''
      }&offset=${offset}&limit=${limit}`,
      method: 'GET',
      withCredentials: true,
    });
  }
  createGame({
    caseIds,
    double,
    isPrivate,
    playersCount,
    winnersSplit,
    winnersCount,
    jokerMode,
  }: RequestBody) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/battles/create`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        isPrivate,
        double,
        playersCount,
        playersInTeam: double ? 2 : 1,
        caseIds,
        winnersCount,
        settings: { winnersSplit, jokerMode },
      }),
    });
  }
  addBot({ battleId, team }: { battleId: number; team?: number }) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/battles/${battleId}/join/bot`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        team,
      }),
    });
  }
  joinGame(battleId: number, team: number) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/battles/${battleId}/join`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ team }),
    });
  }
  getCasesByIds(ids: number[]) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${
        socketService.domain
      }api/cases/suitcases/many/${ids.join('%2C%20')}`,
    });
  }
  addEmoji(battleId: number, emojiId: number) {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/cases/battles/${battleId}/emoji`,
      method: 'POST',
      withCredentials: true,
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ emojiId }),
    });
  }
}
export default new CasesBattleRepository();
