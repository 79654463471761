import { ajax } from 'rxjs/ajax';
import { socketService } from 'services/socket.service';

class RefillRepository {
  fetchEventTickets() {
    return ajax({
      url: `${process.env.PREFIX_GATEWAY_URL}${socketService.domain}api/leaders-race/tickets/refill-bonus-left`,
      method: 'GET',
      withCredentials: true,
      crossDomain: true,
    });
  }

  fetchRefillMethod(userId: number, alpha2Code: string) {
    return ajax({
      url: `https://pay.csgofastbackend.com/methods/${userId}?country=${alpha2Code}&v3=1`,
      method: 'GET',
      withCredentials: true,
    });
  }
  fetchReferralBonus() {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/referral-campaigns/current`,
      method: 'GET',
      withCredentials: true,
    });
  }
  fetchUserCountry() {
    return ajax({
      url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/country`,
      method: 'GET',
    });
  }

  // Deleted in task FAST-5191
  // fetchCheckTrustUser(id: number) {
  //   return ajax({
  //     url: `https://pay.csgofastbackend.com/payment-trust/${id}`,
  //     method: 'GET',
  //     withCredentials: true,
  //     crossDomain: true,
  //   })
  // }
}

export default new RefillRepository();
