import { Record } from 'immutable';

export type IWalletType =
  | 'default'
  | 'email'
  | 'wallet'
  | 'phone'
  | 'skins'
  | 'full_name'
  | 'cpf'
  | 'cpfName'
  | 'steam';
/**
 Категории платежных методов.
 */
export enum PaymentTypes {
  POPULAR = 'popular',
  REGIONAL = 'regional',
  CARDS = 'cards',
  CRYPTO = 'crypto',
}
export interface IPaymentsDto {
  success: boolean;
  lastSuccess: IPayMethod | null;
  depositBonus: number;
  payFee: IPayFee;
  systems: IPayMethod[];
  regional: IPayMethod[];
  popular: IPayMethod[];
  time: number;
}
export interface IPayMethodGate {
  id: number;
  gate_id: number;
  name: string;
  icon: string | boolean;
  is_enabled: boolean;
  title: string;
  method?: string;
  comission: string;
  walletType: IWalletType;
  currency: string[];
  method_name: string;
  minPayment: number;
  maxPayment: number;
}

export type IPayMethodGates = IPayMethodGate[];

export interface IPayMethod {
  gates: IPayMethodGate[];
  name: string;
  title: string;
  is_disabled: boolean;
  group_name?: string;
  problems?: string;
  commission?: string;
  icon?: string;
  bonus?: number[];
}

export interface IPayFee {
  [key: string]: number;
}

export interface IPayMethods {
  systems: IPayMethod[];
  regional: IPayMethod[];
  popular: IPayMethod[];
}

export type ILastPayment = {
  gate: string;
  method: string;
};

export type IRefill = {
  refillCountry?: string;
  autoRefillCountry: string;
  loading: boolean;
  config: IPayMethods;
  lastPayment: ILastPayment | null;
  trust: boolean;
  events: {
    tickets: number;
    bonus: number;
  };
};

export type IRefillRecord = Record<IRefill>;

export interface IPaymentsDtoV2 {
  depositBonus: number;
  methods: IPaymentMethodV2[];
  payFee: IPayFee;
  success: boolean;
  time: number;
}

export interface IPaymentMethodV2 {
  currency: string[];
  id: number;
  method_name: string;
  method_title: string;
  gate_title: string;
  gate_id: number;
  minPayment: number;
  maxPayment: number;
  walletType: IWalletType;
  icon: boolean | string;
  is_popular: boolean;
  is_terminal: boolean;
  bonus: number;
  group_name: PaymentTypes[];
  feeString: string;
  hash: string;
  sort: number;
  fees: {
    percent: number;
    fixed: number;
  };
}
