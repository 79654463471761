import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { CoinInput, CurrencyText } from '@components/currency';
import { Button } from '@components/Common';
import {
  useBonusRefillEvent,
  useCreatePay,
  useGetDataForWalletTypeFullName,
  useWalletPay,
} from '../../../hooks';
import { IPayMethod, IPayMethodGates } from '../../../interfaces/reduces.refill.interfaces';
import { currencyService } from '../../../../../services/currency';
import { RefillWithBonus } from '../refill-with-bonus/refill-with-bonus';
import { useUserReferrerInfo } from 'core/User';
import { isEqual } from 'lodash';
import { Container, ErrorSpan, Fields } from './pay-controls.styles';
import { CustomInput } from '../custom-input';

interface FormInput {
  coinInput: string;
  firstName: string;
  phone: string;
  surName: string;
  cpf: string;
  cpfName: string;
}

export enum WalletFieldEnum {
  FULL_NAME = 'full_name',
  PHONE = 'phone',
  CPF = 'cpf',
  CPF_NAME = 'cpfName',
}

interface IPayControls {
  method: IPayMethod;
  amount: number;
  handleChangeAmount: (value: number) => void;
  gate: number;
  gates: IPayMethodGates;
}

export const PayControls: FC<IPayControls> = React.memo(
  ({ method, amount, handleChangeAmount, gate, gates }) => {
    const [handleCreatePay] = useCreatePay();
    const {
      wallet,
      name,
      surname,
      onChangeName,
      onChangeSurname,
      nameFieldError,
      surnameFieldError,
      errorNameField,
      errorSurnameField,
    } = useGetDataForWalletTypeFullName();
    const { wallet: anotherWallet, changeWallet, walletFieldError, error } = useWalletPay();

    const { t } = useTranslation();
    const { bonus } = useBonusRefillEvent();
    const { referrerInfo } = useUserReferrerInfo();
    const boost = bonus / 100;

    const currentGate = gates[gate];
    const minPayment = gates[gate]?.minPayment ?? 0;
    const walletType = gates[gate]?.walletType || '';
    const min = +currencyService.convertBySelectedRateKey(minPayment, 'fastcoins_refill') * 100;
    const max = +currencyService.convertBySelectedRateKey(100000, 'fastcoins_refill') * 100;

    useEffect(() => {
      handleChangeAmount(min);
    }, [min, handleChangeAmount]);

    const {
      register,
      formState: { errors },
      handleSubmit,
    } = useForm<FormInput>();

    const onSubmit: SubmitHandler<FormInput> = () => {
      switch (walletType) {
        case 'full_name':
          !name && nameFieldError(true);
          !surname && surnameFieldError(true);

          if (name && surname) {
            handleCreatePay({
              name: method?.name,
              value: amount,
              gate: currentGate,
              isOpenNewTab: true,
              wallet,
            })();
          }
          break;

        case 'phone':
          !anotherWallet && walletFieldError(true);

          if (anotherWallet) {
            handleCreatePay({
              name: method?.name,
              value: amount,
              gate: currentGate,
              isOpenNewTab: true,
              wallet: anotherWallet,
            })();
          }
          break;

        case 'cpf':
          anotherWallet.length !== 14 && walletFieldError(true);
          if (anotherWallet.length === 14) {
            handleCreatePay({
              name: method?.name,
              value: amount,
              gate: currentGate,
              isOpenNewTab: true,
              wallet: anotherWallet,
            })();
          }
          break;

        case 'cpfName':
          const trimName = name.trim();
          const trimSurname = surname.trim();

          !trimName && nameFieldError(true);
          !trimSurname && surnameFieldError(true);
          anotherWallet.length !== 14 && walletFieldError(true);

          if (anotherWallet.length === 14 && trimName && trimSurname) {
            handleCreatePay({
              name: method?.name,
              value: amount,
              gate: currentGate,
              isOpenNewTab: true,
              wallet: `${anotherWallet}_${trimName}_${trimSurname}`,
            })();
          }
          break;

        case 'email':
        case 'skins':
        case 'wallet':
        case 'default':
          handleCreatePay({
            name: method?.name,
            value: amount,
            gate: currentGate,
            isOpenNewTab: true,
          })();
          break;

        default:
          break;
      }
    };

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (walletType) {
        changeWallet(e, walletType);
      }
    };

    const walletField: Record<WalletFieldEnum, JSX.Element> = {
      [WalletFieldEnum.FULL_NAME]: (
        <Fields>
          <div>{t('name')}</div>
          <CustomInput
            isError={errorNameField}
            onFocus={() => nameFieldError(false)}
            onChange={onChangeName}
            value={name}
            type="text"
            maxLength={24}
            name="firstName"
            register={(e: HTMLInputElement, params: { required: boolean }) => register(e, params)}
            params={{ required: walletType === 'full_name' && !name }}
          />
          {errors.firstName?.type === 'required' && <ErrorSpan>{t('required')}</ErrorSpan>}
          <div>{t('surname')}</div>
          <CustomInput
            isError={errorSurnameField}
            onFocus={() => surnameFieldError(false)}
            onChange={onChangeSurname}
            value={surname}
            type="text"
            maxLength={24}
            name="surName"
            register={(e: HTMLInputElement, params: { required: boolean }) => register(e, params)}
            params={{ required: walletType === 'full_name' && !surname }}
          />
          {errors.surName?.type === 'required' && <ErrorSpan>{t('required')}</ErrorSpan>}
        </Fields>
      ),
      [WalletFieldEnum.PHONE]: (
        <Fields>
          <div>{t('phone')}</div>
          <CustomInput
            isError={error}
            isPhone={true}
            name={walletType}
            onChange={changeWallet}
            onFocus={() => walletFieldError(false)}
            params={{ required: !anotherWallet }}
            register={(e: HTMLInputElement, params: { required: boolean }) => register(e, params)}
            type="number"
            value={anotherWallet}
          />
          {errors.phone?.type === 'required' && <ErrorSpan>{t('required')}</ErrorSpan>}
        </Fields>
      ),
      [WalletFieldEnum.CPF]: (
        <Fields>
          <div>{t('cpf')}</div>
          <CustomInput
            isError={error}
            name={walletType}
            onChange={changeHandler}
            onFocus={() => walletFieldError(false)}
            placeholder="000.000.000-00"
            register={register}
            type="text"
            value={anotherWallet}
          />
          {error && (
            <ErrorSpan>
              {anotherWallet && anotherWallet.length !== 14 ? t('cpfFormatError') : t('required')}
            </ErrorSpan>
          )}
        </Fields>
      ),
      [WalletFieldEnum.CPF_NAME]: (
        <Fields>
          <div>{t('cpf')}</div>
          <CustomInput
            isError={error}
            name={walletType}
            onChange={changeHandler}
            onFocus={() => walletFieldError(false)}
            placeholder="000.000.000-00"
            register={register}
            type="text"
            value={anotherWallet}
          />
          {error && (
            <ErrorSpan>
              {anotherWallet && anotherWallet.length !== 14 ? t('cpfFormatError') : t('required')}
            </ErrorSpan>
          )}
          <div>{t('name')}</div>
          <CustomInput
            isError={errorNameField}
            onFocus={() => nameFieldError(false)}
            onChange={e => onChangeName(e, true)}
            value={name}
            type="text"
            maxLength={24}
            name="firstName"
            register={(e: HTMLInputElement, params: { required: boolean }) => register(e, params)}
            params={{ required: walletType === 'full_name' && !name }}
          />
          {errorNameField && <ErrorSpan>{t('required')}</ErrorSpan>}
          <div>{t('surname')}</div>
          <CustomInput
            isError={errorSurnameField}
            onFocus={() => surnameFieldError(false)}
            onChange={e => onChangeSurname(e, true)}
            value={surname}
            type="text"
            maxLength={24}
            name="surName"
            register={(e: HTMLInputElement, params: { required: boolean }) => register(e, params)}
            params={{ required: walletType === 'full_name' && !surname }}
          />
          {errorSurnameField && <ErrorSpan>{t('required')}</ErrorSpan>}
        </Fields>
      ),
    };

    return (
      <Container>
        <Fields>
          <div>{t('Refill sum:')}</div>
          <div className="input-wrapper">
            <CoinInput
              value={amount}
              setValue={handleChangeAmount}
              min={min}
              max={max}
              register={(e: HTMLInputElement, params: { required: boolean }) => register(e, params)}
              required={true}
            />
            {!!boost && (
              <div className="amount-bonuses">
                {t('Bonus:')}
                +<CurrencyText value={amount * 0.1} />
              </div>
            )}
          </div>
          {errors.coinInput?.type === 'required' && <ErrorSpan>{t('required')}</ErrorSpan>}
        </Fields>
        {walletField[walletType as WalletFieldEnum]}
        {/*<div className="event-description">*/}
        {/*  <i className="icon-coupon" />*/}
        {/*  {t('Get your yellow ticket')}*/}
        {/*</div>*/}
        <Button
          className="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={amount < minPayment / 100 || !currentGate?.is_enabled}
        >
          {t('Refill balance')}
        </Button>
        <RefillWithBonus
          bonus={method?.bonus[gate]}
          rewarded={referrerInfo.rewarded}
          reward={referrerInfo.reward}
          type={referrerInfo.type}
          currentPrice={amount / 100}
        />
      </Container>
    );
  },
  isEqual
);
