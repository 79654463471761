import { combineReducers } from 'redux';

import { getAppFilterRegex, getClassicGameSettings } from '../../core/AppShell/selectors';

import { getClassicGameData, getClassicGameTradesDictionary } from './selectors';

import { getCrossGameId } from '../../core/CrossServices/selectors';

import { totalItems } from './classic-game.utils';

import { reinitialize as reinitializeApp } from '../../core/AppShell/duck';
import { combineEpics, ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { socketService } from '../../services/socket.service';
import { concat } from 'rxjs';
import { catchError, concatMap, debounceTime, map, pluck, switchMap } from 'rxjs/operators';
import { updateFastInventoryFilter } from '../../core/User/ducks/fast-inventory.duck';
import { MergeType } from '../../core/User/interfaces';
import { ParticipateDialogs } from '../../core/interfaces';
import { dialogOff } from '../../core/ducks/dialog';
import { getRandomInt } from 'core/utils';

const prefix = 'app/classic-game/';

// Actions
const SHOW_TAPE = `${prefix}SHOW_TAPE`;

const SET_WINNER = `${prefix}SET_WINNER`;
const SHOW_WINNER = `${prefix}SHOW_WINNER`;

const UPDATE_LADDER = `${prefix}UPDATE_LADDER`;

const OVER_THE_GAME = `${prefix}OVER_THE_GAME`;
const CLEAR_GAME = `${prefix}CLEAR_GAME`;
const UPDATE_GAME = `${prefix}UPDATE_GAME`;
const UPDATE_GAME_TAPE = `${prefix}UPDATE_GAME_TAPE`;
const UPDATE_TRADES = `${prefix}UPDATE_TRADES`;

const UPDATE_TIMER_VALUE = `${prefix}UPDATE_TIMER_VALUE`;

const OPEN_GAME_TRADE = `${prefix}OPEN_GAME_TRADE`;
const CLOSE_GAME_TRADE = `${prefix}CLOSE_GAME_TRADE`;

const CREATE_PARTICIPATE = `${prefix}CREATE_PARTICIPATE`;

const ATTACH_LISTENERS = `${prefix}ATTACH_LISTENERS`;
const DETACH_LISTENERS = `${prefix}DETACH_LISTENERS`;

// reducers
const initialState = {
  itemsNum: 0,
  md5: '',
  number: 0,
  prize: 0,
  rand: '',
  tape: [],
  timeOldEnds: 0,
  trades: [],
  winner: 0,
};

// game data
const data = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_GAME:
      const { trades } = action.payload;
      const itemsNum = Array.isArray(trades) && trades.length ? trades[0].itemsNum : 0;
      return {
        ...state,
        ...action.payload,
        itemsNum: state.itemsNum + itemsNum,
      };

    case CLEAR_GAME:
      return initialState;

    default:
      return state;
  }
};

// обмены вынесены в отдельный редюсер-словарь для удобного обслуживания
// (чтобы не мердждить бесконечно массив с обменами)
const tradesByID = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TRADES: {
      return { ...state, ...action.payload };
    }
    case CLEAR_GAME: {
      return {};
    }
    default: {
      return state;
    }
  }
};
// ключи для словаря лежат в отдельном редюсере
const tradesIDs = (state = [], action) => {
  switch (action.type) {
    case UPDATE_TRADES: {
      const newIds = Object.keys(action.payload).filter(id => !state.includes(id));

      return [...state, ...newIds];
    }
    case CLEAR_GAME: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const tapePosition = (state = 0, action) => {
  switch (action.type) {
    case UPDATE_GAME_TAPE: {
      const widthItem = 60;
      const offsetToWinPosition = 10;
      const randomOffset = getRandomInt(-26, 26);
      return -widthItem * (action.payload - offsetToWinPosition) - widthItem / 2 + randomOffset;
    }
    case CLEAR_GAME: {
      return 0;
    }
    default: {
      return state;
    }
  }
};

const tapeIsVisible = (state = false, action) => {
  switch (action.type) {
    case SHOW_TAPE: {
      return true;
    }
    case CLEAR_GAME: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const gameIsOver = (state = false, action) => {
  switch (action.type) {
    case OVER_THE_GAME: {
      return true;
    }
    case CLEAR_GAME: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const timerValue = (state = 0, action) => {
  switch (action.type) {
    case UPDATE_TIMER_VALUE: {
      return action.payload;
    }
    case CLEAR_GAME: {
      return 0;
    }
    default: {
      return state;
    }
  }
};

const winner = (state = {}, action) => {
  switch (action.type) {
    case SET_WINNER: {
      return action.payload;
    }
    case CLEAR_GAME: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const gameWinnerVisibleStatus = (state = false, action) => {
  switch (action.type) {
    case SHOW_WINNER: {
      return true;
    }
    case CLEAR_GAME: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const gameTradeWindowStatus = (state = false, action) => {
  switch (action.type) {
    case OPEN_GAME_TRADE: {
      return true;
    }
    case CLOSE_GAME_TRADE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

// webViewUrl
const tradeUrl = (state = '', action) => {
  switch (action.type) {
    case OPEN_GAME_TRADE: {
      return action.payload;
    }
    case CLOSE_GAME_TRADE: {
      return '';
    }
    default: {
      return state;
    }
  }
};

// update bet on ladder
const ladder = (state = [], action) => {
  switch (action.type) {
    case UPDATE_LADDER: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  timerValue,
  data,
  ladder,
  gameIsOver,
  tradeUrl,
  winner,
  gameWinnerVisibleStatus,
  windows: combineReducers({
    gameTradeWindowStatus,
  }),
  trades: combineReducers({
    byID: tradesByID,
    allIDs: tradesIDs,
  }),
  tapeStatus: combineReducers({
    tapePosition,
    tapeIsVisible,
  }),
});

export default reducer;

// action creators

export const attachClassic = () => ({
  type: ATTACH_LISTENERS,
});

export const detachClassic = () => ({
  type: DETACH_LISTENERS,
});

export const initGame = data => dispatch => {
  if (!data) {
    return;
  }
  if (data.trades !== null && totalItems(data.trades) < data.itemsNum) {
    return reinitializeApp();
  }

  dispatch({ type: UPDATE_GAME, payload: data });
  dispatch(updateTrades(data.trades));

  if (data.tape !== null && data.tape.length > 0) {
    dispatch(updateTape(data.tape, false));
  }
};

export const updateGame = data => (dispatch, getState) => {
  const updates = Object.keys(data);
  // если новая игра - сбрасывает данные предыдущей.
  if (updates.includes('number')) {
    const { number } = getClassicGameData(getState());

    if (data.number !== null && data.number !== number) {
      dispatch(clearGame());
    }
  }
  // обновляет данные, полученные с сервера
  dispatch({ type: UPDATE_GAME, payload: data });

  if (updates.includes('trades')) {
    dispatch(updateTrades(data.trades));
  }

  if (updates.includes('tape')) {
    dispatch(updateTape(data.tape, true));
  }
};

export const clearGame = () => ({
  type: CLEAR_GAME,
});

const updateTrades = updatedTrades => (dispatch, getState) => {
  if (updatedTrades === null) return;
  // текущие обмены в виде массива
  const trades = getClassicGameTradesDictionary(getState());
  // заменяет слова, содержащие запрещенный контекст
  const filterRegex = getAppFilterRegex(getState());
  const regex = new RegExp(filterRegex, 'gi');

  const gameId = getCrossGameId(getState());

  // преобразует все обмены в нужный формат
  // пересчитывает шанс, меняет имя в случае наличия запрещенного контекста
  updatedTrades = [...updatedTrades].map(trade => ({
    ...trade,
    //  достаем трейды по айди кросс сервиса
    items: trade.items[gameId],
    //замена запрещенных сочетаний в никнейме
    playerName: trade.playerName.replace(regex, '***'),
  }));

  // преобразует данные в объект-словарь
  const tradeDictionary = updatedTrades.reduce((acc, item) => {
    const playerId = item.playerId;
    const isDuplicate = acc[playerId] && acc[playerId].betId === item.betId;

    return {
      ...acc,
      [playerId]: {
        ...item,
        betId: isDuplicate ? acc[playerId].betId : item.betId,
        items: !isDuplicate && acc[playerId] ? [...acc[playerId].items, ...item.items] : item.items,
        original:
          !isDuplicate && acc[playerId]
            ? [...acc[playerId].original, ...item.original]
            : item.original,
        totalPrice:
          !isDuplicate && acc[playerId]
            ? acc[playerId].totalPrice + item.totalPrice
            : item.totalPrice,
      },
    };
  }, trades);

  dispatch({ type: UPDATE_TRADES, payload: tradeDictionary });
};

const updateTape = (tape, update) => (dispatch, getState) => {
  if (tape === null) return;
  dispatch(setWinner());
  const { roulette_ms } = getClassicGameSettings(getState());
  // для рассчета длины ленты
  dispatch({ type: UPDATE_GAME_TAPE, payload: tape.length });
  // для ее демонстрации
  dispatch({ type: SHOW_TAPE });
  // заканчивать игру по окончанию таймаута (только в update-методе)
  if (update) {
    setTimeout(() => dispatch({ type: OVER_THE_GAME }), roulette_ms);
  }
};

const setWinner = () => (dispatch, getState) => {
  const trades = getClassicGameTradesDictionary(getState());
  // winner - id победителя
  const { winner } = getClassicGameData(getState());
  // выбор игрока из словаря с игроками
  const gameWinner = trades[winner];
  // победный билет
  // const ticket = Math.round(prize * parseFloat(rand)) * 100

  // вещь победителя
  const winnerItem = gameWinner.items[0];
  dispatch({
    type: SET_WINNER,
    payload: { ...gameWinner, item: winnerItem },
  });
};

export const showWinner = () => ({
  type: SHOW_WINNER,
});

export const updateLadder = ladder => ({
  type: UPDATE_LADDER,
  payload: ladder,
});

export const fetchCreateParticipate = items => ({
  type: CREATE_PARTICIPATE,
  payload: {
    items,
  },
});

export const eventsTypes = [{ event: 'rooms:classic:update', action: updateGame }];

const participationEpic = action$ =>
  action$.pipe(
    ofType(CREATE_PARTICIPATE),
    debounceTime(700),
    pluck('payload', 'items'),
    map(items =>
      Array.from(items).reduce(
        (acc, { id, inventoryItem }) =>
          id ? [...acc, { type: 'skin', id, amount: inventoryItem?.price }] : acc,
        []
      )
    ),
    switchMap(items =>
      ajax({
        url: `${process.env.PREFIX_NEW_GATEWAY_URL}${socketService.domain}api/classic/bet`,
        method: 'POST',
        withCredentials: true,
        crossDomain: true,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ appId: 730, items }),
      }).pipe(
        concatMap(() => [
          dialogOff(ParticipateDialogs.PARTICIPATE_DIALOG),
          updateFastInventoryFilter(['page', 'number'], 1, MergeType.RESET),
        ]),
        catchError(() =>
          concat([
            dialogOff(ParticipateDialogs.PARTICIPATE_DIALOG),
            updateFastInventoryFilter(['page', 'number'], 1, MergeType.RESET),
          ])
        )
      )
    )
  );

export const classicEpics = combineEpics(participationEpic);
